import LoadingOverlay from '@rsa-digital/evo-shared-components/components/LoadingOverlay';
import accountClient from 'api/accountClient';
import { PageProps } from 'gatsby';
import React, { useCallback, useEffect, useReducer } from 'react';
import Layout from 'components/Layout';
import { SessionExpiryOption } from 'components/SessionExpiryWrapper';
import {
  getAzureLogoutUrlWithRedirectPath,
  getAzureLogoutUrlWithRedirectUrl,
} from 'helpers/azureRoutingHelper';
import { getQueryParam } from 'helpers/getQueryParam';
import { accountRoutes } from 'helpers/routingHelper';
import { useBaseApiRequestHandler } from 'helpers/useApiRequestHandler';
import { usePageTracking } from 'helpers/usePageTracking';
import useResetReduxState from 'helpers/useResetReduxState';
import { useUser } from 'state/user/state';

const Login: React.FC<PageProps> = ({ location }) => {
  const metaTitle = 'Log out';
  const resetReduxState = useResetReduxState();
  const requestHandler = useBaseApiRequestHandler();
  const [logOutAttempted, setLogOutAttempted] = useReducer(() => true, false);
  const [user, setUser] = useUser();
  const logoutRedirectUrl = getQueryParam(location, 'redirectUrl');

  usePageTracking(metaTitle);

  const resetStateAndRedirect = useCallback(() => {
    setUser({ isLoggedIn: false });
    resetReduxState();

    const redirectUrl = logoutRedirectUrl
      ? getAzureLogoutUrlWithRedirectUrl(logoutRedirectUrl)
      : getAzureLogoutUrlWithRedirectPath(accountRoutes.login);

    window.location.assign(redirectUrl);
  }, [logoutRedirectUrl, resetReduxState, setUser]);

  useEffect(() => {
    if (!user || logOutAttempted) {
      return;
    }
    setLogOutAttempted();
    if (user.isLoggedIn) {
      requestHandler(() => accountClient.logout()).then(resetStateAndRedirect);
    } else {
      resetStateAndRedirect();
    }
  }, [
    logOutAttempted,
    requestHandler,
    resetReduxState,
    resetStateAndRedirect,
    setUser,
    user,
  ]);

  return (
    <Layout
      meta={{ meta_title: metaTitle }}
      pageType="accountArea"
      sessionExpiryOption={SessionExpiryOption.SILENT_SESSION_EXPIRY}
      hideAccountMenu>
      <LoadingOverlay loadingMessage="Logging you out" />
    </Layout>
  );
};

export default Login;
